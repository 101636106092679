import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  onSameUrlNavigation: 'reload'
};

import { MainComponent } from './components/main/main.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { ScienceComponent } from './components/science/science.component';
import { GamesComponent } from './components/games/games.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { HomeComponent } from './components/home/home.component';


const routes: Routes = [
  {
    path: 'privacy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'main',
    component: MainComponent,
  },
  {
    path: 'how-it-works',
    component: HowItWorksComponent,
  },
  {
    path: 'science',
    component: ScienceComponent,
  },
  {
    path: 'games',
    component: GamesComponent,
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  },
  { path: '',   redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
