import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit {

  infoCards = [
    {
      title: 'Increasing personal satisfaction is key',
      text: 'The challenge to improve a score or win a game increases personal satisfaction and feelings of accomplishment',
      position: 'left-top',
      border: '20px 20px 0 20px'
    },
    {
      title: 'Family and staff have the opportunity to play familiar games with residents',
      text: 'Changes in a resident’s activity level provides both family and care givers early insights to modify current engagement or interaction strategies',
      position: 'left-bottom',
      border: '20px 20px 0 20px'
    },
    {
      title: 'It’s “something to talk about”',
      text: 'Sharing accomplishments gives the resident a new reason to talk with care givers or family members',
      position: 'right-top',
      border: '20px 20px 20px 0'
    },
    {
      title: 'Real time reporting provides staff and family members insights into the resident’s mental well being',
      text: '',
      position: 'right-bottom',
      border: '20px 20px 20px 0'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
