import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})

export class ContactUsComponent implements OnInit {

  @ViewChild('submitModal', { static: false }) submitModal: ElementRef;

  contactForm: FormGroup;
  emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  modals = [
    {
      title: 'Thank you!',
      text: 'Your message has been successfully sent. We will contact you very soon!',
      icon: 'assets/success-icon.png'
    },
    {
      title: 'Error',
      text: 'A temporary server error has occurred. Please try again.',
      icon: 'assets/warning-icon.png'
    }
  ];
  currentModal;

  constructor(
    private formBuilder: FormBuilder,
    public renderer: Renderer2,
    private http: HttpClient
  ) {
    this.currentModal = this.modals[0];
  }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      fullName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
      info: new FormControl('')
    });
  }

  onSubmit(formData) {
    if (this.contactForm.valid) {
      this.postFormData(formData);
      this.contactForm.reset();
    }
  }

  postFormData(formData) {
    this.http.post('https://cognitrix-landing.herokuapp.com/api/contact', formData).subscribe(
      res => {
        this.currentModal = this.modals[0];
        this.showSubmitModal();
      },
      err => {
        this.currentModal = this.modals[1];
        this.showSubmitModal();
      }
    );
  }

  showSubmitModal() {
    this.renderer.setStyle(this.submitModal.nativeElement, 'display', 'flex');
    setTimeout(() => {
      this.hideModal();
    }, 5000);
  }

  hideModal() {
    this.renderer.setStyle(this.submitModal.nativeElement, 'display', 'none');
  }
}
