import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  scrollToSection(event) {
    document.getElementById(event).scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
