import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() navigate: EventEmitter<any> = new EventEmitter();

  menuOpened = false;

  constructor() { }

  ngOnInit() {
  }

  navigateToSection(sectionId) {
    this.menuOpened = false;
    this.navigate.emit(sectionId);
  }

  // redirectToLogin() {
  //   window.location.href = 'http://www.cognitrix.ai/sign-in';
  // }
}
