import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  // redirectToLogin() {
  //   window.location.href = 'http://www.cognitrix.ai/sign-in';
  // }

  // redirectToSignUp() {
  //   window.location.href = 'http://www.cognitrix.ai/sign-up';
  // }

}
