import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})

export class HowItWorksComponent implements OnInit {

  blocks = [
    {img: 'assets/how-it-works-1.png', text: 'Download the app and invite your family members to a private and secure network where you’ll find a library of classic games in addition to fun and stimulating memory exercises.'},
    {img: 'assets/how-it-works-2.png', text: 'Family Game Night tracks performance over time and makes it fun for your family to compete.'},
    {img: 'assets/how-it-works-3.png', text: 'Best in class customer support and ease of use make the platform fun and easy to use for older loved ones.'},
    {img: 'assets/how-it-works-4.png', text: 'Have fun!'}
  ];

  constructor() { }

  ngOnInit() {
  }

}
